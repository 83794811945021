import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useWindowScroll } from 'react-use'
import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ButtonBase from '@material-ui/core/ButtonBase'
import Fade from '@material-ui/core/Fade'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
import messages from '@utils/messages'
import windowScrollTo from '@utils/windowScrollTo'
import { sendCommonDetailClickEvent } from '@src/utils/analytics'
import {
  ProductDetailsEventCategory as eventCategory,
  ProductDetailsEventAction as eventAction,
} from '@utils/analytics/enums'
import {
  regexForStripHTML,
  regexForStripSymbols,
} from '@src/utils/analytics/coreAnalytics'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    right: theme.spacing(6),
    bottom: theme.spacing(31.5),
    backgroundColor: theme.palette.primary.main,
    height: 56,
    width: 56,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'uppercase',
    boxShadow: '0 2px 4px rgba(0,0,0,.2)',
    zIndex: 2,
  },
  icon: {
    fontSize: 28,
    marginTop: -5,
  },
  text: { fontSize: 14, marginTop: -6 },
}))

const handleScrollToTop = ({
  productNumber,
  productBrand,
  productName,
  productVariant,
}) => {
  const legacyGAPayload = productNumber && {
    eventCategory: eventCategory.ProductDetailsPage,
    eventAction: eventAction.JumpToTop,
    eventLabel: productNumber,
    eventInteractionType: 0,
  }

  sendCommonDetailClickEvent(
    {
      event: 'navigation_interaction',
      action: 'in page click',
      detail: 'jump to top',
      component: 'body',
      elementType: 'anchor button',
      elementText: 'top',
      coreEvent: 'no',
      productBrand,
      productId: productNumber,
      productName,
      productVariant,
    },
    legacyGAPayload
  )

  windowScrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  })
}

interface BackToTopButtonInput {
  productBrand?: string
  productName?: string
  productNumber?: string
  productVariant?: string
}

const BackToTopButton: React.FC<BackToTopButtonInput> = ({
  productNumber,
  productBrand,
  productName,
  productVariant,
}) => {
  const { y } = useWindowScroll()
  const visible = y > 1000
  const classes = useStyles()

  return (
    <Fade in={visible}>
      <ButtonBase
        className={classes.root}
        data-testid="back-to-top-button"
        onClick={() =>
          handleScrollToTop({
            productNumber,
            productBrand,
            productName: productName
              ?.replace(regexForStripHTML, '')
              .replace(regexForStripSymbols, '')
              .toLowerCase(),
            productVariant,
          })
        }
      >
        <div>
          <KeyboardArrowUp className={classes.icon} />
        </div>
        <FormattedMessage {...messages.TOP}>
          {(text) => <div className={classes.text}>{text}</div>}
        </FormattedMessage>
      </ButtonBase>
    </Fade>
  )
}

export default BackToTopButton
