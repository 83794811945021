import { defineMessages } from 'react-intl'

export interface selectorToolMessage {
  id: string
  defaultMessage: string
}

export const selectorToolMessages = defineMessages({
  ANTIBODY_SELECTOR_TOOL_TITLE: {
    id: 'ANTIBODY_SELECTOR_TOOL_TITLE',
    defaultMessage: 'Antibody Product Selector',
  },
  ANTIBODY_SELECTOR_TOOL_DESCRIPTION: {
    id: 'ANTIBODY_SELECTOR_TOOL_DESCRIPTION',
    defaultMessage:
      'Explore our collection of recombinant, polyclonal, and monoclonal antibodies.',
  },
  ANTIBODY_SELECTOR_TOOL_SEARCH_LABEL: {
    id: 'ANTIBODY_SELECTOR_TOOL_SEARCH_LABEL',
    defaultMessage: 'Antigen/Antibody Name',
  },
  ANTIBODY_SELECTOR_TOOL_CLONALITY: {
    id: 'ANTIBODY_SELECTOR_TOOL_CLONALITY',
    defaultMessage: 'Clonality',
  },
  ANTIBODY_SELECTOR_TOOL_TECHNIQUE: {
    id: 'ANTIBODY_SELECTOR_TOOL_TECHNIQUE',
    defaultMessage: 'Technique',
  },
  ANTIBODY_SELECTOR_TOOL_SPECIES_REACTIVITY: {
    id: 'ANTIBODY_SELECTOR_TOOL_SPECIES_REACTIVITY',
    defaultMessage: 'Species Reactivity',
  },
  ANTIBODY_SELECTOR_TOOL_CONJUGATE: {
    id: 'ANTIBODY_SELECTOR_TOOL_CONJUGATE',
    defaultMessage: 'Conjugate',
  },
  MILLEX_FILTER_SELECTOR_TOOL_TITLE: {
    id: 'MILLEX_FILTER_SELECTOR_TOOL_TITLE',
    defaultMessage: 'Millex Filter Selector',
  },
  MILLEX_FILTER_SELECTOR_TOOL_CHOOSE_SYRINGE: {
    id: 'MILLEX_FILTER_SELECTOR_TOOL_CHOOSE_SYRINGE',
    defaultMessage:
      'Choose the right syringe filter for your sample to obtain the best quality data, filtration performance, and reduce waste.',
  },
  MILLEX_FILTER_SELECTOR_TOOL_ANSWER_REQUIRED_QUESTION: {
    id: 'MILLEX_FILTER_SELECTOR_TOOL_ANSWER_REQUIRED_QUESTION',
    defaultMessage:
      'Answer the required question below to narrow your selection.',
  },
  MILLEX_FILTER_SELECTOR_TOOL_PRODUCTS_INTENDED: {
    id: 'MILLEX_FILTER_SELECTOR_TOOL_PRODUCTS_INTENDED',
    defaultMessage:
      'Products are intended for research and laboratory use only.',
  },
  MILLEX_FILTER_SELECTOR_TOOL_REQUIRED: {
    id: 'MILLEX_FILTER_SELECTOR_TOOL_REQUIRED',
    defaultMessage: '* Required',
  },
  MILLEX_FILTER_SELECTOR_TOOL_WHAT_TYPE: {
    id: 'MILLEX_FILTER_SELECTOR_TOOL_WHAT_TYPE',
    defaultMessage: 'What type of filter do you need?',
  },
  MILLEX_FILTER_SELECTOR_TOOL_WHAT_APPLICATIONS: {
    id: 'MILLEX_FILTER_SELECTOR_TOOL_WHAT_APPLICATIONS',
    defaultMessage:
      'What applications describe your intended usage with the device?',
  },
  MILLEX_FILTER_SELECTOR_TOOL_PORE_SIZE_UNIT: {
    id: 'MILLEX_FILTER_SELECTOR_TOOL_PORE_SIZE_UNIT',
    defaultMessage: 'What is your desired pore size (µm)?',
  },
  MILLEX_FILTER_SELECTOR_TOOL_SAMPLE_VOLUME_UNIT: {
    id: 'MILLEX_FILTER_SELECTOR_TOOL_SAMPLE_VOLUME_UNIT',
    defaultMessage: 'Desired maximum sample volume (mL)?',
  },
})

export default selectorToolMessages
