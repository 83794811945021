import React, { FC, useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import selectorToolMessages from '@utils/MessageSets/defineSelectorToolMessages'
import { selectorToolMapper } from './selectorToolMapper'
import { MillexSelectorTool } from './MillexSelectorTool'
import { useProductSearchQuery } from '@src/queries/ProductSearchQuery.generated'
import {
  ProductSearchGroup,
  ProductSearchType,
  FacetInput,
} from '@src/types/graphql-types'
import { isServer } from '@src/utils/isServer'
import { useProductCatalogFilter } from '@src/utils/useProductCatalogFilter'
import { useSelectorToolSearch } from './useSelectorToolSearch'
import { sendSelectorToolEvent } from '@src/utils/analytics'

type MillexFilterSelectorToolProps = {
  onClose: () => void
  onDismiss: () => void
  open: boolean
  toolType: string
  productNumber?: string
  productName?: string | undefined
  productBrand?: string
}

const isTest = process.env.NODE_ENV === 'test'

export const MillexFilterSelectorTool: FC<MillexFilterSelectorToolProps> = ({
  onClose,
  onDismiss,
  open,
  toolType,
  productNumber,
  productName,
  productBrand,
}) => {
  const { formatMessage } = useIntl()
  const productCatalogFilter = useProductCatalogFilter()
  const [handleSelectorSearch] = useSelectorToolSearch()

  const query = localStorage?.getItem('searchQuery')
    ? JSON.parse(localStorage.getItem('searchQuery') || '')
    : {}
  const clientFetchPolicy = !isServer() ? 'cache-first' : 'network-only'
  // TODO: fix the test so it doesn't rely on 'no-cache'
  const fetchPolicy = isTest ? 'no-cache' : clientFetchPolicy
  const { data, refetch } = useProductSearchQuery({
    fetchPolicy,
    variables: {
      searchTerm: toolType,
      page: query.page || 1,
      group: ProductSearchGroup.Substance,
      selectedFacets: [
        {
          key: 'facet_web_eqp',
          options: ['400'],
        },
        {
          key: 'facet_product_line',
          options: ['Millex<SUP>&#8482;</SUP>'],
        },
      ],
      sort: query.sort || 'relevance',
      type: query.type
        ? (query.type.toUpperCase() as ProductSearchType)
        : undefined,
      region: query.region,
      filter: productCatalogFilter,
    },
  })

  /**
   * Gets the mapped data for the selected tool from
   * the PDP page toolType. ex: antibodies
   */
  const selectedTool = selectorToolMapper[toolType]

  const { facets = [] } = data?.getProductSearchResults || {}

  // Facets being constructed to get passed to the SelectorTool
  const selectorToolFacets = useMemo(
    () =>
      selectedTool?.facets?.map((selectedFacet) => {
        /**
         * Getting the facet information from the mapper to
         * one of the actual facets that we get from the list
         * of all the facets from the getProductSearchResults
         */
        const mappedFacet = facets?.find(
          (facet) => facet.key === selectedFacet.key
        )

        return {
          ...(mappedFacet || {}),
          ...selectedFacet,
          label: formatMessage(selectorToolMessages[selectedFacet.label]),
          /**
           * Mapping through the facet options from the data.getProductSearchResults
           * and returning a label value in order to pass it into the
           * <SelectorTool /> component
           */
          options:
            mappedFacet?.options?.map((facetOption) => ({
              ...facetOption,
              label: facetOption.value,
            })) ?? [],
        }
      }),
    [facets, selectedTool]
  )

  /**
   * If there is a search for the antibody, need to append
   * the term antibody to the end of the search term. If no
   * search is entered and still antibody search, JUST
   * the term antibody is used by default
   */
  const handleSearchClick = useCallback(
    (term?: string, selectedFacets?: string[] | null) => {
      /**
       * We want the antibody facets to be powered by the
       * antibodies facet on the SRP. If it is an antibody
       * that is being searched for, then we need to make
       * sure and add the facet_product_category of
       * antibodies along with any other facet that may
       * be selected by the user
       */
      const newSelectedFacets = [
        ...(Array.isArray(selectedFacets) ? selectedFacets : []),
        ...['facet_web_eqp:400', 'facet_product_line:Millex<SUP>&#8482;</SUP>'],
      ]

      const searchTerm = !term ? toolType : term

      handleSelectorSearch(query, searchTerm, newSelectedFacets)

      const selectedFacetMap = {}
      selectedFacets?.forEach((selectedFacet) => {
        const facetForHashMap = selectedFacet.split(':')

        const [facetKey, facetValue] = facetForHashMap
        selectedFacetMap[facetKey]
          ? selectedFacetMap[facetKey].push(facetValue)
          : (selectedFacetMap[facetKey] = [facetValue])
      })

      sendSelectorToolEvent({
        action: 'show millex filter products',
        detail: `type of filter : ${selectedFacetMap?.['facet_web_sterilization'] || ''} > application : ${
          selectedFacetMap?.['facet_web_titertest_app']?.join(',') || ''
        } > desired pore size : ${
          selectedFacetMap?.['facet_web_pore_size_dim1_um']?.join(',') || ''
        } > desired maximum sample volume : ${
          selectedFacetMap?.['facet_web_comp_volume_ml']?.join(',') || ''
        }`,
        section: 'millex filter selector',
        component: 'slider',
        elementType: 'button',
        elementText: 'show products',
        productId: productNumber,
        productBrand,
        productName,
        basePayloadOverrides: {
          event: 'millex_filter_selector_interaction',
        },
      })
    },
    [handleSelectorSearch, query, toolType]
  )

  const handleRefetchFacets = useCallback((newSelectedFacets: string[]) => {
    const facetHashMap = {}

    newSelectedFacets.forEach((newSelectedFacet) => {
      const facetForHashMap = newSelectedFacet.split(':')
      const [facetKey, facetValue] = facetForHashMap
      const facetInHashMap = facetHashMap?.[facetKey]

      facetHashMap[facetKey] = {
        key: facetKey,
        options:
          facetKey in facetHashMap
            ? [...facetInHashMap.options, facetValue]
            : [facetValue],
      }
    })

    refetch({
      searchTerm: toolType,
      page: query.page || 1,
      group: ProductSearchGroup.Substance,
      selectedFacets: [
        ...(Object.values(facetHashMap) as FacetInput[]),
        ...[
          {
            key: 'facet_web_eqp',
            options: ['400'],
          },
          {
            key: 'facet_product_line',
            options: ['Millex<SUP>&#8482;</SUP>'],
          },
        ],
      ],
      sort: query.sort || 'relevance',
      type: query.type
        ? (query.type.toUpperCase() as ProductSearchType)
        : undefined,
      region: query.region,
      filter: productCatalogFilter,
    })
  }, [])

  return (
    <MillexSelectorTool
      onClose={onClose}
      onDismiss={onDismiss}
      onFetchNewFacets={handleRefetchFacets}
      onSearchClick={handleSearchClick}
      open={open}
      selectorDescriptions={[
        formatMessage(
          selectorToolMessages.MILLEX_FILTER_SELECTOR_TOOL_CHOOSE_SYRINGE
        ),
        formatMessage(
          selectorToolMessages.MILLEX_FILTER_SELECTOR_TOOL_ANSWER_REQUIRED_QUESTION
        ),
        formatMessage(
          selectorToolMessages.MILLEX_FILTER_SELECTOR_TOOL_PRODUCTS_INTENDED
        ),
        formatMessage(
          selectorToolMessages.MILLEX_FILTER_SELECTOR_TOOL_REQUIRED
        ),
      ]}
      selectorTitle={formatMessage(
        selectorToolMessages.MILLEX_FILTER_SELECTOR_TOOL_TITLE
      )}
      selectorToolFacets={selectorToolFacets}
      productNumber={productNumber}
      productName={productName}
      productBrand={productBrand}
    />
  )
}
