import { ChangeEvent, FC, useRef, useState } from 'react'
import { Box, Button, Drawer, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import { SelectorToolDropdown } from './SelectorToolDropdown'
import {
  RadioButtonGroupRef,
  SelectorToolRadioButtonGroup,
} from './SelectorToolRadioButtonGroup'
import { SelectorToolProps } from './types'
import {
  MultiSelectRef,
  SelectorToolMultiSelect,
} from './SelectorToolMultiSelect'
import { sendSelectorToolEvent } from '@src/utils/analytics'

const useStyles = makeStyles((theme) => ({
  actionContainer: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      flexWrap: 'wrap',
    },
  },
  closeIcon: {
    cursor: 'pointer',
    height: theme.spacing(6),
    position: 'absolute',
    right: theme.spacing(6),
    top: theme.spacing(6),
    width: theme.spacing(6),
  },
  drawer: {
    '& .MuiPaper-root': {
      backgroundColor: theme.palette.background.grey,
    },
  },
  drawerContent: {
    padding: theme.spacing(18, 16),
    width: 520,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(18, 9),
      width: '100%',
    },
  },
  descriptionText: {
    marginBottom: theme.spacing(6),
  },
  resetBtn: {
    cursor: 'pointer',
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(6),
    },
  },
  searchText: {
    marginBottom: theme.spacing(6),
  },
  showProductsBtn: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  titleText: {
    marginBottom: theme.spacing(6),
  },
}))

export const MillexSelectorTool: FC<SelectorToolProps> = ({
  anchor = 'right',
  onSearchClick,
  onClose,
  onDismiss,
  onFetchNewFacets,
  open,
  productNumber,
  productName,
  productBrand,
  selectorTitle = 'Selector Tool',
  selectorToolFacets,
  selectorDescriptions = [],
}) => {
  const [selectedFacets, setSelectedFacets] = useState<string[] | null>(null)
  const classes = useStyles()
  const radioGroupRefs = useRef<(RadioButtonGroupRef | null)[]>([])
  const multiSelectRefs = useRef<(MultiSelectRef | null)[]>([])

  const handleMultiFacetSelect = (
    e: ChangeEvent<{ name?: string | undefined; value: unknown }>,
    key: string
  ) => {
    const { value } = e.target
    /**
     * We will first filter out all of the values that are
     * associated with the specific facet key. This is done so we
     * can add them back in below from the new values array
     * that comes back from the MultiSelect component.
     */
    const newSelectedFacets = [
      ...(Array.isArray(selectedFacets) ? selectedFacets : []),
    ].filter((newSelectedFacet) => newSelectedFacet.split(':')[0] !== key)

    /**
     * Pushing the new values that are selected from the
     * MultiSelect checkbox into the current facets that are selected.
     * This includes the facets from the other singular selection
     * dropdowns, radio buttons, etc.
     */
    ;(value as string[]).forEach((facetValue: string) => {
      newSelectedFacets.push(`${key}:${facetValue}`)
    })

    setSelectedFacets(newSelectedFacets)
    onFetchNewFacets?.(newSelectedFacets)
  }

  const handleFacetSelect = (e: ChangeEvent<HTMLInputElement>, key: string) => {
    const { value } = e.target
    const newSelectedFacet: string = `${key}:${value}`
    const newSelectedFacets: string[] = Array.isArray(selectedFacets)
      ? selectedFacets.filter((selected) => selected.split(':')[0] !== key)
      : []

    newSelectedFacets.push(newSelectedFacet)

    setSelectedFacets(newSelectedFacets)
    onFetchNewFacets?.(newSelectedFacets)
  }

  const handleReset = () => {
    radioGroupRefs.current.forEach((ref) => ref?.reset())
    multiSelectRefs.current.forEach((ref) => ref?.reset())
    setSelectedFacets(null)
    onFetchNewFacets?.([])
    sendSelectorToolEvent({
      action: 'reset millex filter selector tool selection',
      section: 'millex filter selector',
      component: 'slider',
      elementType: 'link',
      elementText: 'reset',
      productId: productNumber || '',
      productBrand: productBrand || '',
      productName,
      basePayloadOverrides: {
        event: 'millex_filter_selector_interaction',
      },
    })
  }

  const handleIgnore = () => {
    setSelectedFacets([])
    onDismiss()
  }

  const handleClose = () => {
    setSelectedFacets([])
    onClose()
  }

  const handleSearchClick = () => {
    onSearchClick?.('millex', selectedFacets)
    handleClose()
  }

  return (
    <Drawer
      anchor={anchor}
      className={classes.drawer}
      data-testid="selector-tool"
      open={open}
      onClose={handleIgnore}
    >
      <CloseIcon
        className={classes.closeIcon}
        data-testid="selector-tool-close-icon"
        onClick={handleClose}
      />
      <Box className={classes.drawerContent}>
        <Typography className={classes.titleText} variant="h1">
          {selectorTitle}
        </Typography>
        {selectorDescriptions.length > 0 &&
          selectorDescriptions.map((selectorDescription) => (
            <Typography
              className={classes.descriptionText}
              key={selectorDescription}
              variant="body1"
            >
              {selectorDescription}
            </Typography>
          ))}
        <Box display="flex" flexDirection="column" gridRowGap={18}>
          {selectorToolFacets?.map((item, index) => {
            const { componentType, key, label, options, required } = item
            const facetsToCheck = new Set([
              'facet_web_sterilization:non-sterile',
              'facet_web_sterilization:sterile',
            ])
            const oneIncluded = selectedFacets?.some((item) =>
              facetsToCheck.has(item)
            )
            const disabled = !options?.length || !oneIncluded

            if (componentType === 'select') {
              return (
                <SelectorToolDropdown
                  disabled={disabled}
                  key={key}
                  item={label}
                  onChange={(e) => handleFacetSelect(e, key)}
                  options={options}
                  required={required}
                  variant={disabled ? 'filled' : 'outlined'}
                />
              )
            } else if (componentType === 'radio') {
              return (
                <SelectorToolRadioButtonGroup
                  key={key}
                  label={label}
                  onChange={(e) => handleFacetSelect(e, key)}
                  options={options}
                  ref={(el) => (radioGroupRefs.current[index] = el)}
                  required={required}
                />
              )
            } else if (componentType === 'multiselect') {
              return (
                <SelectorToolMultiSelect
                  disabled={disabled}
                  key={key}
                  item={label}
                  onChange={(e) => handleMultiFacetSelect(e, key)}
                  options={options}
                  ref={(el) => (multiSelectRefs.current[index] = el)}
                  required={required}
                  variant={disabled ? 'filled' : 'outlined'}
                />
              )
            }
            return null
          })}
        </Box>
        <Box
          alignItems="center"
          className={classes.actionContainer}
          display="flex"
          justifyContent={selectedFacets ? 'space-between' : 'flex-end'}
          mt={8}
        >
          {selectedFacets && (
            <Typography
              className={classes.resetBtn}
              component="span"
              color="primary"
              onClick={handleReset}
            >
              <FormattedMessage id="RESET" defaultMessage="Reset" />
            </Typography>
          )}
          <Button
            color="primary"
            className={classes.showProductsBtn}
            disabled={!selectedFacets}
            name="select-search-button"
            onClick={handleSearchClick}
            variant="contained"
          >
            <FormattedMessage
              id="SELECTOR_TOOL_BUTTON_TEXT"
              defaultMessage="Show Products"
            />
          </Button>
        </Box>
      </Box>
    </Drawer>
  )
}
