import { FC } from 'react'
import { Box, Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'

type ProductSelectorProps = {
  onButtonClick: () => void
  onLinkClick: () => void
  isAemPageProductSelector?: boolean
}

const useStyles = makeStyles((theme) => ({
  container: {
    borderTop: `1px solid ${theme.palette.grey[400]}`,
  },

  aemProductSelectorContainer: {
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.primary.light,
    width: '100vw',
    position: 'relative',
    left: '50%',
    right: '50%',
    display: 'flex',
    margin: '0 -50vw',
    flexDirection: 'column',
    paddingTop: theme.spacing(18.75),
    paddingBottom: theme.spacing(18),
  },
  mainText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  selectorToolText: {
    cursor: 'pointer',
    fontWeight: theme.typography.fontWeightBold,
    margin: 0,
    padding: 0,
  },
  textContainer: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  trySelectorButton: {
    padding: theme.spacing(2, 6),
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
      width: '100%',
    },
  },
}))

export const ProductSelector: FC<ProductSelectorProps> = ({
  onButtonClick,
  onLinkClick,
  isAemPageProductSelector = false,
}): JSX.Element => {
  const classes = useStyles()

  return (
    <Box
      alignItems="center"
      className={
        isAemPageProductSelector
          ? classes.aemProductSelectorContainer
          : classes.container
      }
      data-testid="product-selector"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      px={4}
      py={8}
    >
      <Box
        alignItems="center"
        className={classes.textContainer}
        display="flex"
        mb={5}
      >
        <Typography className={classes.mainText} component="p" variant="body1">
          <FormattedMessage
            id="NOT_FINDING_RIGHT_PRODUCT"
            defaultMessage="Not finding the right product?"
          />{' '}
          &nbsp;
        </Typography>
        {isAemPageProductSelector ? (
          <Typography component="p" variant="body1">
            <FormattedMessage
              id="TRY_PRODUCT_SELECTOR_WITHOUT_LINK"
              defaultMessage="Try our Product Selector Tool."
            />
          </Typography>
        ) : (
          <Typography component="p" variant="body1">
            <FormattedMessage
              id="TRY_OUR_PRODUCT_SELECTOR"
              defaultMessage="Try our {productSelectorTool}"
              values={{
                productSelectorTool: (
                  <Typography
                    className={classes.selectorToolText}
                    component="span"
                    color="primary"
                    onClick={onLinkClick}
                  >
                    <FormattedMessage
                      id="PRODUCT_SELECTOR_TOOL"
                      defaultMessage="Product Selector Tool"
                    />
                    .
                  </Typography>
                ),
              }}
            />
          </Typography>
        )}
      </Box>
      <Button
        className={classes.trySelectorButton}
        color="primary"
        onClick={onButtonClick}
        variant="outlined"
      >
        {isAemPageProductSelector ? (
          <FormattedMessage
            id="TRY_THE_SELECTOR"
            defaultMessage="Try the Selector"
          />
        ) : (
          <FormattedMessage
            id="TRY_OUR_SELECTOR"
            defaultMessage="Try Our Selector"
          />
        )}
      </Button>
    </Box>
  )
}
