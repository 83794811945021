import { ChangeEvent, FC, useState } from 'react'
import {
  FormControlLabel,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import DOMPurify from 'isomorphic-dompurify'
import { SelectorToolDropdownProps } from './types'

const useStyles = makeStyles((theme) => ({
  formControlLabel: {
    '& .MuiTypography-root': {
      width: '100%',
    },
    // custom focus state
    '& .MuiSelect-root:focus': {
      backgroundColor: 'transparent',
    },
  },
  formLabel: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(1),
  },
  textField: {
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.common.white,
      height: 40,
      '&.Mui-disabled': {
        backgroundColor: theme.palette.grey[200],
      },
    },
  },
}))

export const SelectorToolDropdown: FC<SelectorToolDropdownProps> = ({
  disabled = false,
  item,
  labelPlacement = 'top',
  onChange,
  options,
  required,
  variant = 'outlined',
}) => {
  const [selectValue, setSelectValue] = useState<string | number>('')
  const classes = useStyles()

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    onChange?.(e)
    setSelectValue(value)
  }

  return (
    <FormControlLabel
      className={classes.formControlLabel}
      control={
        <TextField
          className={classes.textField}
          data-testid="selector-tool-dropdown-text-field"
          disabled={disabled}
          fullWidth
          onChange={handleChange}
          required={required}
          select
          value={selectValue}
          variant={variant}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Typography
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(option.label),
                }}
              />
            </MenuItem>
          ))}
        </TextField>
      }
      data-testid="selector-tool-dropdown-form-control-label"
      label={
        <Typography className={classes.formLabel} variant="body2">
          {item}
        </Typography>
      }
      labelPlacement={labelPlacement}
      style={{ margin: 0, width: '100%' }}
    />
  )
}
