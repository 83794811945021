import { ChangeEvent, FC, useState } from 'react'
import { FormControlLabel, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { SelectorToolTextFieldProps } from './types'

const useStyles = makeStyles((theme) => ({
  formControlLabel: {
    '& .MuiTypography-root': {
      width: '100%',
    },
  },
  formLabel: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(1),
  },
  textField: {
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.common.white,
      height: 40,
    },
  },
}))

export const SelectorToolTextField: FC<SelectorToolTextFieldProps> = ({
  label,
  labelPlacement = 'top',
  onChange,
}) => {
  const [textValue, setTextValue] = useState('')
  const classes = useStyles()

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    onChange?.(e)
    setTextValue(value)
  }

  return (
    <FormControlLabel
      className={classes.formControlLabel}
      control={
        <TextField
          className={classes.textField}
          data-testid="selector-tool-text-field"
          fullWidth
          inputProps={{ 'data-testid': 'selector-tool-input' }}
          onChange={handleChange}
          value={textValue}
          variant="outlined"
        />
      }
      data-testid="selector-tool-text-field-form-control-label"
      label={
        <Typography className={classes.formLabel} variant="body2">
          {label}
        </Typography>
      }
      labelPlacement={labelPlacement}
      style={{ margin: 0, width: '100%' }}
    />
  )
}
