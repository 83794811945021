import getConfig from 'next/config'
import {
  PathUtils,
  ModelManager,
  ModelClient,
  Model,
} from '@adobe/aem-spa-page-model-manager'
import { AEM_STATIC_CONTENT } from '@src/queries/AemStaticContentQuery'
import { AEM_HOMEPAGE } from '@src/queries/AemHomepageQuery'
import { CURRENT_USER } from '@src/queries/CurrentUserQuery'
import { CurrentUserQuery } from '@src/queries/CurrentUserQuery.generated'
import { parseRegionalUrl } from '@src/utils/regional'

const {
  publicRuntimeConfig: { featureFlags, aemHost },
} = getConfig()

// This is used to get the path on AEM for which we will retrieve the model json while leaving out query params.
// Using a generic url as we only care about the pathname, and the constructor requires more than just the path.
// Cannot use asPath from context, as the asPath gets possibly modified for CustomPDPs
const getPath = async (ctx: ExtendedNextPageContext, asPath: string) => {
  let user: CurrentUserQuery | null = null

  // Try to get latest currentUser result, then catch and log any errors as errors
  try {
    const result = await ctx.apolloClient.query({
      query: CURRENT_USER,
    })
    user = result.data
  } catch (e) {}

  const shouldFetchDejaAemContent =
    ctx.pathname === '/[country]/[language]' &&
    user?.me.__typename === 'LoggedInUser' &&
    user?.me.metadata.isDejaEnabled

  const shouldFetchMTrackAemContent =
    ctx.pathname ===
      '/[country]/[language]/order-center/orders/[orderId]/[trackingId]/[shipToZipCode]' ||
    ctx.pathname ===
      '/[country]/[language]/order-center/orders/[orderId]/[trackingId]/[shipToZipCode]/[carrierId]'

  if (shouldFetchMTrackAemContent) {
    const parsedUrl = parseRegionalUrl(ctx.asPath)

    return new URL(
      `${parsedUrl.basename.country}/${parsedUrl.basename.language}/m-track`,
      'http://example.com'
    ).pathname
  } else if (shouldFetchDejaAemContent) {
    const parsedUrl = parseRegionalUrl(ctx.asPath)
    return new URL(
      `${parsedUrl.pathname}/returning-customer-home`,
      'http://example.com'
    ).pathname
  }
  return new URL(asPath, 'http://example.com').pathname
}

const getAemProps = ({ asPath }: { asPath?: string }) => {
  const locationPathname = asPath?.replace(/\/$/, '') + '.html'
  const cqPath = PathUtils.convertToModelUrl(locationPathname)
  return {
    locationPathname,
    cqPath,
  }
}

const aemQueryList = {
  aemHomepage: AEM_HOMEPAGE,
  aemStaticContent: AEM_STATIC_CONTENT,
}

const updateAemMetaTagsProps = (aemProps) => {
  if (!aemProps.metaDataObj) return null

  const { isNoFollow, isNoIndex, seoSchemas = [] } = aemProps.metaDataObj

  const robots: string[] = []
  if (isNoIndex) {
    robots.push('noindex')
  }
  if (isNoFollow) {
    robots.push('nofollow')
  }

  return {
    ...aemProps.metaDataObj,
    robots,
    schemas: seoSchemas,
  }
}

const getCustomPdpPath = async (asPath, productHash) => {
  const asPathArr = asPath.split('/')

  /* URL structure expected to be something like: /US/en/product/SIGMA/OLIGO?foo=bar
   * so expect the following asPathArr indecies:
   * 0 - empty
   * 1 - US (country)
   * 2 - en (language)
   * 3 - product
   * 4 - SIGMA (brand)
   * 5 - OLIGO (productKey)
   */
  if (productHash && asPathArr.length > 5 && asPathArr[3] === 'product') {
    return [
      asPathArr[0],
      asPathArr[1],
      asPathArr[2],
      'custom-pdp',
      productHash,
    ].join('/')
  }

  return asPath
}

//For the flavors and fragrances certificates, the actual URL seen on the PDP page is of the format:
// /flavors-and-fragrances/<name of the certificate>/<brand>/<squashed product number>
// But the AEM template for F&F is of the format: /flavors-and-fragrances/<name of the certificate>
// So the productKey and brand need to be removed from the end of the URL in order to match AEM template.
const getFandFCertificatesPagePath = (asPath: string) => {
  const asPathArr = asPath.split('/')
  const asPathTruncatedArr = asPathArr.slice(0, asPathArr.length - 2)
  return asPathTruncatedArr.join('/')
}

const getInitialAEMProps = async (ctx: ExtendedNextPageContext) => {
  let isAemFullPage = true
  let asPath = ctx.asPath || '/'

  // Only run GIP if we're using the AEM CMS
  if (!featureFlags.aemCms) {
    return { props: {} }
  }

  // If this request is for a missing SDS pdf, set 404 and abort as an error
  if (asPath.includes('/sds/')) {
    if (ctx.res) ctx.res.statusCode = 404
    return { error: true, asPath }
  }

  const { productHash } = ctx.query
  if (productHash) {
    const asPathPdp = await getCustomPdpPath(asPath, productHash)
    if (asPath !== asPathPdp) {
      asPath = asPathPdp
      isAemFullPage = false
    }
  }
  const isFandFPage = asPath.split('/')[4] === 'flavors-and-fragrances'
  if (isFandFPage) {
    const asPathFandF = getFandFCertificatesPagePath(asPath)
    asPath = asPathFandF
  }

  // If this is homepage path, set query to homepage, else set query to static content
  const aemQuery =
    ctx.pathname === '/[country]/[language]'
      ? 'aemHomepage'
      : 'aemStaticContent'

  // If the path is requesting an individual file reject the request with a 404.
  const urlWithoutParams = asPath.split('?')[0] || ''
  if (urlWithoutParams.includes('.')) {
    if (ctx.res) ctx.res.statusCode = 404
    return { error: true, basicError: true, asPath }
  }

  const pathname = await getPath(ctx, asPath)
  const aemProps = getAemProps({ asPath: pathname })
  let notFoundError = false

  const newFetch = async () => {
    // HACK: using cqPath instead of _modelPath as _modelPath inexplicably gives the
    // path without the language and country prepended. See the following log message
    try {
      const result = await ctx.apolloClient.query({
        query: aemQueryList[aemQuery],
        variables: { path: pathname },
      })
      if (result.data) return JSON.parse(result.data[aemQuery])
      else {
        const notOkStatus = `AEM JSON fetch returned with status ${result.errors}`
        notFoundError = true
        console.warn(notOkStatus)
        return {}
      }
    } catch (error) {
      notFoundError = true
      return {}
    }
  }

  ModelClient.prototype.fetch = newFetch
  const modelClient = new ModelClient(aemHost)

  try {
    const modelManagerConfig = {
      path: aemProps.cqPath,
      modelClient,
    }
    let modelManager: Model
    if (typeof window !== 'undefined') {
      modelManager = await ModelManager.initialize(modelManagerConfig)
    } else {
      await ModelManager.initializeAsync({ modelClient })
      modelManager = await ModelManager.getData(modelManagerConfig)
    }
    if (ctx.res && notFoundError) ctx.res.statusCode = 404
    return {
      ...aemProps,
      ...modelManager,
      aemHost,
      asPath,
      error: notFoundError,
      isAemFullPage,
      metaDataObj: updateAemMetaTagsProps(modelManager),
    }
  } catch (error) {
    return { props: { ...aemProps, error: true, asPath } }
  }
}

export default getInitialAEMProps
