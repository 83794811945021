import { ChangeEvent, FC, useState } from 'react'
import { Box, Button, Drawer, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import { SelectorToolTextField } from './SelectorToolTextField'
import { SelectorToolDropdown } from './SelectorToolDropdown'
import { SelectorToolRadioButtonGroup } from './SelectorToolRadioButtonGroup'
import { SelectorToolProps } from './types'
import { SelectorToolMultiSelect } from './SelectorToolMultiSelect'

const useStyles = makeStyles((theme) => ({
  closeIcon: {
    cursor: 'pointer',
    height: theme.spacing(6),
    position: 'absolute',
    right: theme.spacing(6),
    top: theme.spacing(6),
    width: theme.spacing(6),
  },
  drawer: {
    '& .MuiPaper-root': {
      backgroundColor: theme.palette.background.grey,
    },
  },
  drawerContent: {
    padding: theme.spacing(18, 16),
    width: 520,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(18, 9),
      width: '100%',
    },
  },
  descriptionText: {
    marginBottom: theme.spacing(6),
  },
  searchText: {
    marginBottom: theme.spacing(6),
  },
  showProductsBtn: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  titleText: {
    marginBottom: theme.spacing(6),
  },
}))

export const SelectorTool: FC<SelectorToolProps> = ({
  anchor = 'right',
  hasSearchField = true,
  onSearchClick,
  onClose,
  onDismiss,
  onFetchNewFacets,
  open,
  selectorDescription,
  selectorTitle = 'Selector Tool',
  selectorToolFacets,
  textFieldSearchLabel = '',
}) => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [selectedFacets, setSelectedFacets] = useState<string[] | null>(null)
  const classes = useStyles()

  const handleSearchTermChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setSearchTerm(value)
  }

  const handleMultiFacetSelect = (e, key: string) => {
    const { value } = e.target
    /**
     * We will first filter out all of the values that are
     * associated with the specific facet key. This is done so we
     * can add them back in below from the new values array
     * that comes back from the MultiSelect component.
     */
    const newSelectedFacets = [
      ...(Array.isArray(selectedFacets) ? selectedFacets : []),
    ].filter((newSelectedFacet) => newSelectedFacet.split(':')[0] !== key)

    /**
     * Pushing the new values that are selected from the
     * MultiSelect checkbox into the current facets that are selected.
     * This includes the facets from the other singular selection
     * dropdowns, radio buttons, etc.
     */
    value.forEach((facetValue: string) => {
      newSelectedFacets.push(`${key}:${facetValue}`)
    })

    setSelectedFacets(newSelectedFacets)
    onFetchNewFacets?.(newSelectedFacets)
  }

  const handleFacetSelect = (e: ChangeEvent<HTMLInputElement>, key: string) => {
    const { value } = e.target
    const newSelectedFacet: string = `${key}:${value}`
    const newSelectedFacets: string[] = Array.isArray(selectedFacets)
      ? selectedFacets.filter((selected) => selected.split(':')[0] !== key)
      : []

    newSelectedFacets.push(newSelectedFacet)

    setSelectedFacets(newSelectedFacets)
    onFetchNewFacets?.(newSelectedFacets)
  }

  const handleIgnore = () => {
    setSearchTerm('')
    setSelectedFacets([])
    onDismiss()
  }

  const handleClose = () => {
    setSearchTerm('')
    setSelectedFacets([])
    onClose()
  }

  const handleSearchClick = () => {
    onSearchClick?.(searchTerm, selectedFacets)
    handleClose()
  }

  return (
    <Drawer
      anchor={anchor}
      className={classes.drawer}
      data-testid="selector-tool"
      open={open}
      onClose={handleIgnore}
    >
      <CloseIcon
        className={classes.closeIcon}
        data-testid="selector-tool-close-icon"
        onClick={handleClose}
      />
      <Box className={classes.drawerContent}>
        <Typography className={classes.titleText} variant="h1">
          {selectorTitle}
        </Typography>
        {selectorDescription && (
          <Typography className={classes.descriptionText} variant="body1">
            {selectorDescription}
          </Typography>
        )}
        {hasSearchField && (
          <Typography className={classes.searchText} variant="body1">
            <FormattedMessage
              id="SELECTOR_TOOL_SEARCH_TEXT"
              defaultMessage="Enter at least one value to search."
            />
          </Typography>
        )}
        <Box display="flex" flexDirection="column" gridRowGap={18}>
          {hasSearchField && (
            <SelectorToolTextField
              label={textFieldSearchLabel}
              onChange={handleSearchTermChange}
            />
          )}
          {selectorToolFacets?.map((item) => {
            const { componentType, key, label, options } = item
            const disabled = !options?.length

            if (componentType === 'select') {
              return (
                <SelectorToolDropdown
                  disabled={disabled}
                  key={key}
                  item={label}
                  onChange={(e) => handleFacetSelect(e, key)}
                  options={options}
                  variant={disabled ? 'filled' : 'outlined'}
                />
              )
            } else if (componentType === 'radio') {
              return (
                <SelectorToolRadioButtonGroup
                  key={key}
                  label={label}
                  onChange={(e) => handleFacetSelect(e, key)}
                  options={options}
                />
              )
            } else if (componentType === 'multiselect') {
              return (
                <SelectorToolMultiSelect
                  disabled={disabled}
                  key={key}
                  item={label}
                  onChange={(e) => handleMultiFacetSelect(e, key)}
                  options={options}
                  variant={disabled ? 'filled' : 'outlined'}
                />
              )
            }
            return null
          })}
        </Box>
        <Box display="flex" justifyContent="flex-end" mt={8}>
          <Button
            color="primary"
            className={classes.showProductsBtn}
            disabled={!searchTerm && !selectedFacets}
            name="select-search-button"
            onClick={handleSearchClick}
            variant="contained"
          >
            <FormattedMessage
              id="SELECTOR_TOOL_BUTTON_TEXT"
              defaultMessage="Show Products"
            />
          </Button>
        </Box>
      </Box>
    </Drawer>
  )
}
