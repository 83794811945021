import { ChangeEvent, forwardRef, useImperativeHandle, useState } from 'react'
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { SelectorToolRadioButtonGroupProps } from './types'

export type RadioButtonGroupRef = {
  reset: () => void
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    '& .MuiTypography-root': {
      width: '100%',
    },
  },
  formLabel: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(1),
  },
}))

export const SelectorToolRadioButtonGroup = forwardRef<
  RadioButtonGroupRef,
  SelectorToolRadioButtonGroupProps
>(({ label, onChange, options, radioGroupRow = true, required }, ref) => {
  const [radioValue, setRadioValue] = useState<string | number>('')
  const classes = useStyles()

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    onChange?.(e)
    setRadioValue(value)
  }

  useImperativeHandle(ref, () => ({
    reset: () => setRadioValue(''),
  }))

  return (
    <FormControl
      className={classes.formControl}
      data-testid="selector-tool-radio-button-group-form-control"
      required={required}
    >
      <FormLabel
        className={classes.formLabel}
        id={`radio-buttons-group-label-${label}`}
      >
        {label}
      </FormLabel>
      <RadioGroup
        aria-labelledby={`radio-buttons-group-label-${label}`}
        data-testid="selector-tool-radio-button-group"
        name={`radio-buttons-group-${label}`}
        onChange={handleChange}
        row={radioGroupRow}
        value={radioValue}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio color="primary" />}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
})

SelectorToolRadioButtonGroup.displayName = 'SelectorToolRadioButtonGroup'
