import { ChangeEvent, forwardRef, useImperativeHandle, useState } from 'react'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import DOMPurify from 'isomorphic-dompurify'
import { SelectorToolMultiSelectProps } from './types'

export type MultiSelectRef = {
  reset: () => void
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    '& .MuiTypography-root': {
      width: '100%',
    },
  },
  formControlLabel: {
    '& .MuiTypography-root': {
      width: '100%',
    },
    '& .MuiSelect-root:focus': {
      backgroundColor: 'transparent',
    },
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.common.white,
      height: 40,
      '&.Mui-disabled': {
        backgroundColor: theme.palette.grey[200],
      },
    },
  },
  formLabel: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(1),
  },
}))

export const SelectorToolMultiSelect = forwardRef<
  MultiSelectRef,
  SelectorToolMultiSelectProps
>(
  (
    {
      disabled = false,
      item,
      labelPlacement = 'top',
      onChange,
      options,
      required,
      variant = 'outlined',
    },
    ref
  ) => {
    const [selectedOptions, setSelectedOptions] = useState<string[]>([])
    const classes = useStyles()

    const handleChange = (
      e: ChangeEvent<{ name?: string | undefined; value: unknown }>
    ) => {
      const { value } = e.target
      onChange?.(e)
      setSelectedOptions(value as string[])
    }

    useImperativeHandle(ref, () => ({
      reset: () => setSelectedOptions([]),
    }))

    return (
      <FormControl
        className={classes.formControl}
        data-testid="selector-tool-multi-select-form-control"
        required={required}
      >
        <FormControlLabel
          className={classes.formControlLabel}
          control={
            <Select
              data-testid="selector-tool-multi-select"
              disabled={disabled}
              fullWidth
              labelId="selector-tool-multi-select"
              multiple
              onChange={handleChange}
              renderValue={(options) =>
                Array.isArray(options) ? options?.join(', ') : options
              }
              value={selectedOptions}
              variant={variant}
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <Checkbox
                    checked={selectedOptions.indexOf(option.value) > -1}
                    color="primary"
                  />
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(option.label),
                    }}
                  />
                </MenuItem>
              ))}
            </Select>
          }
          data-testid="selector-tool-multi-select-form-control-label"
          label={
            <Typography className={classes.formLabel} variant="body2">
              {item}
            </Typography>
          }
          labelPlacement={labelPlacement}
          style={{ margin: 0, width: '100%' }}
        />
      </FormControl>
    )
  }
)

SelectorToolMultiSelect.displayName = 'SelectorToolMultiSelect'
