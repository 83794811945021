import { useCallback } from 'react'
import { searchResultsRoute, useRouter } from '@src/routes'
import { getValidSearch } from '@utils/searchUtils'
import { SitePreference, useCurrentUser } from '@utils/useCurrentUser'

export const useSelectorToolSearch = () => {
  const router = useRouter()
  const { getSitePreference } = useCurrentUser()

  const handleSelectorSearch = useCallback(
    (query, term: string, selectedFacets: string[]): void => {
      const currentOrgId = getSitePreference(SitePreference.CatalogFilter)
      const isStructureSearch = router.asPath.includes('structure-search')
      let queryTerm = ''

      if (isStructureSearch) queryTerm = 'structure-search'
      else if (term) queryTerm = term
      else queryTerm = ''

      const {
        focus,
        type,
        sort,
        perpage,
        format,
        filters,
        image,
        catalogType,
        region,
      } = query

      const constantSearchTerms = {
        term: queryTerm,
        selectedFacets,
        focus,
        type,
        sort,
        perpage,
        format,
        filters,
        image,
        catalogType,
        region,
      }

      router.push(
        `/search/[searchTerm]${getValidSearch({
          ...constantSearchTerms,
          selectedFacets: selectedFacets,
          page: 1,
          orgId: currentOrgId,
        })}`,
        `${searchResultsRoute.searchResults(queryTerm)}${getValidSearch({
          ...constantSearchTerms,
          selectedFacets: selectedFacets,
          page: 1,
          orgId: currentOrgId,
        })}`
      )
      window.scrollTo(0, 0)
    },
    [router]
  )

  return [handleSelectorSearch]
}
