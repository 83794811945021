import { useCurrentUser } from '@utils/useCurrentUser'
import { UserErpType } from '@src/types/graphql-types'

export const useProductCatalogFilter = () => {
  const {
    isBlueErpIntegrationEnabled,
    userErpType,
    isB2BUser,
    isDarmstadtUser,
  } = useCurrentUser()

  if (!isBlueErpIntegrationEnabled || isDarmstadtUser) {
    return undefined
  }

  if (!isB2BUser) {
    return 'erp_type:all'
  }

  if (isB2BUser && userErpType === UserErpType.Blue) {
    return 'erp_type:blue'
  }

  return undefined
}
