import { EventAction } from '@src/utils/analytics'

export enum SelectorToolType {
  antibody = 'antibody',
  millex = 'millex',
  aemPageAntibody = 'aemPageAntibody',
}

export type ProductSelectorAnalyticsConfig = {
  productId: string
  productKey: string
  productBrandKey: string
  productName: string
}

export type ConfigOverrides = {
  antibodyConfig: Record<string, unknown>
  millexConfig: Record<string, unknown>
  aemPageAntibodyConfig: Record<string, unknown>
}

const defaultConfigOverrides: ConfigOverrides = {
  antibodyConfig: {},
  millexConfig: {},
  aemPageAntibodyConfig: {},
}

export const productSelectorAnalyticsConfig = (
  selectorToolType: SelectorToolType,
  {
    productId,
    productKey,
    productBrandKey,
    productName,
  }: ProductSelectorAnalyticsConfig,
  configOverrides: ConfigOverrides = defaultConfigOverrides
) => {
  const config = {
    antibody: {
      'open button': {
        eventAction: 'open button' as EventAction,
        eventLabel: productId ?? '',
        action: 'open antibody selector tool',
        section: 'compare similar items',
        component: 'body',
        elementType: 'button',
        elementText: 'try our selector',
        productId: productKey,
        productBrand: productBrandKey,
        productName: productName,
      },
      'open link': {
        eventAction: 'open link' as EventAction,
        eventLabel: productId ?? '',
        action: 'open antibody selector tool',
        section: 'compare similar items',
        component: 'body',
        elementType: 'link',
        elementText: 'product selector tool',
        productId: productKey,
        productBrand: productBrandKey,
        productName: productName,
      },
      close: {
        eventAction: 'close' as EventAction,
        eventLabel: productId ?? '',
        action: 'close antibody selector tool',
        section: 'antibody product selector',
        component: 'slider',
        elementType: 'icon',
        productId: productKey,
        productBrand: productBrandKey,
        productName: productName,
      },
      ignore: {
        eventAction: 'ignore' as EventAction,
        eventLabel: productId ?? '',
        action: 'ignore antibody selector tool',
        section: undefined,
        component: undefined,
        elementType: undefined,
        productId: productKey,
        productBrand: productBrandKey,
        productName: productName,
      },
      ...configOverrides.antibodyConfig,
    },
    millex: {
      'open button': {
        action: 'open millex filter selector tool',
        section: 'description',
        component: 'body',
        elementType: 'button',
        elementText: 'try our selector',
        productId: productKey,
        productBrand: productBrandKey,
        productName: productName,
        basePayloadOverrides: {
          event: 'millex_filter_selector_interaction',
        },
      },
      'open link': {
        action: 'open millex filter selector tool',
        section: 'description',
        component: 'body',
        elementType: 'link',
        elementText: 'product selector tool',
        productId: productKey,
        productBrand: productBrandKey,
        productName: productName,
        basePayloadOverrides: {
          event: 'millex_filter_selector_interaction',
        },
      },
      close: {
        action: 'close millex filter selector tool',
        section: 'millex filter selector',
        component: 'slider',
        elementType: 'icon',
        productId: productKey,
        productBrand: productBrandKey,
        productName: productName,
        basePayloadOverrides: {
          event: 'millex_filter_selector_interaction',
        },
      },
      ignore: {
        action: 'ignore millex filter selector tool',
        section: undefined,
        component: undefined,
        elementType: undefined,
        productId: productKey,
        productBrand: productBrandKey,
        productName: productName,
        basePayloadOverrides: {
          event: 'millex_filter_selector_interaction',
        },
      },
      ...configOverrides.millexConfig,
    },
    aemPageAntibody: {
      'open button': {
        eventAction: 'open button' as EventAction,
        eventLabel: productId ?? '',
        action: 'open antibody selector tool',
        section: 'products',
        component: 'body',
        elementType: 'button',
        elementText: 'try our selector',
        productId: productKey || '',
        productBrand: productBrandKey ?? '',
        productName: productName ?? '',
      },
      'open link': {
        eventAction: 'open link' as EventAction,
        eventLabel: productId ?? '',
        action: 'open antibody selector tool',
        section: 'products',
        component: 'body',
        elementType: 'link',
        elementText: 'product selector tool',
        productId: productKey ?? '',
        productBrand: productBrandKey ?? '',
        productName: productName ?? '',
      },
      close: {
        eventAction: 'close' as EventAction,
        eventLabel: productId ?? '',
        action: 'close antibody selector tool',
        section: 'antibody product selector',
        component: 'slider',
        elementType: 'icon',
        productId: productKey ?? '',
        productBrand: productBrandKey ?? '',
        productName: productName ?? '',
      },
      ignore: {
        eventAction: 'ignore' as EventAction,
        eventLabel: productId ?? '',
        action: 'ignore antibody selector tool',
        section: undefined,
        component: undefined,
        elementType: undefined,
        productId: productKey ?? '',
        productBrand: productBrandKey ?? '',
        productName: productName ?? '',
      },
      ...configOverrides.aemPageAntibodyConfig,
    },
  }

  return config[selectorToolType]
}
