// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type GetRecommendedProductsAltQueryVariables = Types.Exact<{
  input: Types.GetRecommendedProductsAltInput;
}>;


export type GetRecommendedProductsAltQuery = { __typename?: 'Query', getRecommendedProductsAlt?: { __typename?: 'AssociatedProducts', products: Array<{ __typename?: 'Product', id?: string | null, productKey: string, productNumber: string, name: string, description?: string | null, gaProductCode?: string | null, isMarketplace: boolean, tags?: string | null, discount?: string | null, brand: { __typename?: 'Brand', key: string, erpKey: string, name?: string | null, color?: string | null }, images: Array<{ __typename?: 'CatalogImage', largeUrl: string, mediumUrl: string, label?: string | null, brandKey?: string | null, altText?: string | null, smallUrl: string, productKey?: string | null, sequence?: number | null }> }> } | null };


export const GetRecommendedProductsAltDocument = gql`
    query GetRecommendedProductsAlt($input: GetRecommendedProductsAltInput!) {
  getRecommendedProductsAlt(input: $input) {
    products {
      id
      productKey
      productNumber
      name
      description
      gaProductCode
      brand {
        key
        erpKey
        name
        color
      }
      images {
        largeUrl
        mediumUrl
        label
        brandKey
        altText
        smallUrl
        productKey
        sequence
      }
      isMarketplace
      tags
      discount
    }
  }
}
    `;
export type GetRecommendedProductsAltComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetRecommendedProductsAltQuery, GetRecommendedProductsAltQueryVariables>, 'query'> & ({ variables: GetRecommendedProductsAltQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetRecommendedProductsAltComponent = (props: GetRecommendedProductsAltComponentProps) => (
      <ApolloReactComponents.Query<GetRecommendedProductsAltQuery, GetRecommendedProductsAltQueryVariables> query={GetRecommendedProductsAltDocument} {...props} />
    );
    

/**
 * __useGetRecommendedProductsAltQuery__
 *
 * To run a query within a React component, call `useGetRecommendedProductsAltQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecommendedProductsAltQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecommendedProductsAltQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRecommendedProductsAltQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetRecommendedProductsAltQuery, GetRecommendedProductsAltQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetRecommendedProductsAltQuery, GetRecommendedProductsAltQueryVariables>(GetRecommendedProductsAltDocument, options);
      }
export function useGetRecommendedProductsAltLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRecommendedProductsAltQuery, GetRecommendedProductsAltQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetRecommendedProductsAltQuery, GetRecommendedProductsAltQueryVariables>(GetRecommendedProductsAltDocument, options);
        }
export type GetRecommendedProductsAltQueryHookResult = ReturnType<typeof useGetRecommendedProductsAltQuery>;
export type GetRecommendedProductsAltLazyQueryHookResult = ReturnType<typeof useGetRecommendedProductsAltLazyQuery>;
export type GetRecommendedProductsAltQueryResult = ApolloReactCommon.QueryResult<GetRecommendedProductsAltQuery, GetRecommendedProductsAltQueryVariables>;